import { BackgroundColor, LogoType, isSanityFullWidthImage, isSanityPageHero } from "@util/types";
import { BlocksContent, Button, Drawer, Image, PackageEnquiryForm, SearchMenu } from "@global";
import { ButtonWrapper, PageWidth } from "@util/standard";
import {
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  colors,
  colorsRGB,
  fontWeights,
} from "@util/constants";
import { Maybe, SanityFullWidthImage, SanityPageHero } from "@graphql-types";
import React, { MouseEvent, useRef, useState } from "react";
import { em, toPlainText } from "@util/helper";

import { HomeSellLogo } from "@util/svg";
import styled from "styled-components";
import { useStore } from "@state/store";

interface Props {
  data: Maybe<SanityFullWidthImage> | Maybe<SanityPageHero> | undefined;
  type?: LogoType;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  overflowIsHidden?: boolean;
}

export const Section = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled(PageWidth)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  color: ${colors.white};
  padding: 240px 0 150px;

  @media only screen and (min-width: ${TABLET_BREAKPOINT + 1}px) {
    h1 {
      font-size: ${em(75, 16)};
    }
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    h1 {
      padding: 0 10px;
    }
  }
`;

const ScrollButton = styled.button`
  background-image: url("data:image/svg+xml,%3Csvg width='151' height='66' viewBox='0 0 151 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M150.461 66H0C4.83588 28.7616 36.6743 0 75.2303 0C113.786 0 145.625 28.7616 150.461 66Z' fill='%23133456'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  color: ${colors.white};
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 150px;
  height: 66px;
  transform: translateX(-50%);
  font-weight: ${fontWeights.bold};
  padding: 10px 0 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 123px;
    height: 54px;
  }
`;

const PageHeroSection = styled.section<{
  hasImage: boolean;
  hasDesc: boolean;
  overflowIsHidden?: boolean;
}>`
  ${({ hasImage, hasDesc, overflowIsHidden }) =>
    hasImage &&
    !hasDesc &&
    !overflowIsHidden &&
    `
    padding-bottom: 80px;
  `}
`;

export const PageWidthWrapper = styled.div<{
  hasImage: boolean;
  type?: LogoType;
}>`
  background: ${({ type }) => (type ? colors[type] : colors.main)};
  color: ${colors.white};

  ${({ hasImage }) =>
    hasImage
      ? `
    padding: 130px 0 0;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      padding: 140px 0 0;
    }
  `
      : `
    padding: 200px 0 180px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      padding: 180px 0 140px;
    }
  `}
`;

const GridWrapper = styled.div<{ hasImage: boolean }>`
  display: grid;
  width: 100%;

  ${({ hasImage }) =>
    hasImage &&
    `
    grid-template-columns: 3fr 4fr;
    grid-gap: 60px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      grid-template-columns: 1fr;
    }
  `}
`;

const TextWrapper = styled.div<{ hasImage: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  ${({ hasImage }) =>
    hasImage &&
    `
    justify-content: center;
    margin-bottom: 80px;

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      margin-bottom: 0;
    }
  `}

  h1:not(:last-child) {
    margin-bottom: 35px;
  }

  svg {
    height: 42px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    svg {
      height: 27px;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 120%;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: -80px;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100% + 40px);
    margin-right: -40px;
  }
`;

const DescriptionWrapper = styled.div<{
  hasImage: boolean;
  background?: BackgroundColor;
  type?: LogoType;
}>`
  ${({ hasImage }) =>
    hasImage
      ? `
    padding: 200px 0 140px;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      padding: 160px 0 100px;
    }
  `
      : `
    padding: 120px 0;

    @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
      padding: 80px 0;
    }
  `}

  ${({ background, type }) =>
    type
      ? `
    background: ${colorsRGB[type](0.1)};
  `
      : background &&
        `
    background: ${colors[background]};
  `}
`;

const SearchWrapper = styled.div`
  position: absolute;
  top: 120px;
  width: 90%;

  @media only screen and (max-width: ${SMALL_LAPTOP_BREAKPOINT}px) {
    width: calc(100% - 40px);
  }
`;

const Hero = ({ data, type, onClick, overflowIsHidden }: Props) => {
  if (!data) return null;
  const sectionEl = useRef<HTMLDivElement>(null);
  const [openEnquire, setOpenEnquire] = useState(false);

  const handleClick = () => {
    const height = sectionEl?.current?.offsetHeight;

    if (height) {
      window.scroll({
        top: height,
        behavior: "smooth",
      });
    }
  };

  const { setEnquiryFormOpen } = useStore();

  if (isSanityFullWidthImage(data)) {
    const { blockContent, image, links } = data;

    return (
      <Section ref={sectionEl} onClick={onClick}>
        {image && (
          <>
            <Image data={image} isBackground overlay={"0.5"} />
            <ContentWrapper>
              <BlocksContent
                data={blockContent}
                margin="0 0 40px"
                tabletMargin="0 0 25px"
                textAlign="center"
                isHeading
              />
              {links && links.length > 0 && (
                <ButtonWrapper>
                  {links.map((link, i) => {
                    if (!link) return;

                    return <Button key={link._key} {...link} theme={i === 0 ? "white" : "base"} />;
                  })}
                </ButtonWrapper>
              )}
            </ContentWrapper>
            <ScrollButton onClick={handleClick}>Scroll</ScrollButton>
          </>
        )}
        <SearchWrapper>
          <SearchMenu isWhite redirect />
        </SearchWrapper>
      </Section>
    );
  }

  if (isSanityPageHero(data)) {
    const { title, links, image, blockContent, background } = data;

    if (!title && !image && !blockContent) return null;

    const hasImage = Boolean(image);
    const hasDesc = Boolean(toPlainText(blockContent));

    return (
      <PageHeroSection
        onClick={onClick}
        hasDesc={hasDesc}
        hasImage={hasImage}
        overflowIsHidden={overflowIsHidden}
      >
        {(title || image) && (
          <PageWidthWrapper hasImage={hasImage} type={type}>
            <PageWidth paddingMob={20}>
              <GridWrapper hasImage={hasImage}>
                <TextWrapper hasImage={hasImage}>
                  {type && <HomeSellLogo type={type as LogoType} inverted margin="0 0 20px" />}
                  <h1>{title}</h1>
                  {links && links.length > 0 && (
                    <ButtonWrapper justifyContent="flex-start">
                      {links.map((link, i) => {
                        if (!link) return;

                        return (
                          <Button
                            onClick={() => {
                              return setOpenEnquire(true);
                            }}
                            key={link._key}
                            {...link}
                            theme={type ? "base" : i === 0 ? "whiteOutline" : "white"}
                          />
                        );
                      })}
                    </ButtonWrapper>
                  )}
                </TextWrapper>

                {image && (
                  <ImageWrapper>
                    <Image data={image} isBackground />
                  </ImageWrapper>
                )}
              </GridWrapper>
            </PageWidth>
          </PageWidthWrapper>
        )}
        {hasDesc && (
          <DescriptionWrapper
            hasImage={hasImage}
            type={type}
            background={background as BackgroundColor}
          >
            <PageWidth>
              <BlocksContent data={blockContent} type={type} />
            </PageWidth>
          </DescriptionWrapper>
        )}
        <Drawer visible={openEnquire} onClose={() => setOpenEnquire(false)}>
          <PackageEnquiryForm type={type} />
        </Drawer>
      </PageHeroSection>
    );
  }

  return null;
};

export default Hero;
