import React, { useState } from "react";
import { GridContainer } from "@util/standard";
import { Address } from "@state/types";
import TextField from "../textField";

// Import the regions data from the JSON file
import { regions } from "../../util/regions"; // Adjust this import path based on your project structure
import Select from "@global/select";

interface Props {
  onChange: (data: Address) => void;
  inputs: Address;
  theme?: "outlined" | "filled";
  showLabel?: boolean;
  smallForm?: boolean;
}

const AddressInput = ({ inputs, showLabel, theme, onChange, smallForm }: Props) => {
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [availableDistricts, setAvailableDistricts] = useState<(typeof regions)[0]["Districts"]>(
    [],
  );
  const [availableSuburbs, setAvailableSuburbs] = useState<
    (typeof regions)[0]["Districts"][0]["Suburbs"]
  >([]);

  const handleChange = (input: string, value: string) => {
    if (!onChange) return;
    onChange({ ...inputs, [input]: value });
  };

  // Handle region change and populate districts based on selected region
  const handleRegionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const region = e.target.value;
    setSelectedRegion(region);

    const regionData = regions.find(r => r.Name === region);
    setAvailableDistricts(regionData ? regionData.Districts : []);
    setSelectedDistrict(""); // Reset district when region changes
    setAvailableSuburbs([]); // Reset suburbs
    handleChange("region", region);
  };

  // Handle district change and populate suburbs based on selected district
  const handleDistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const district = e.target.value;
    setSelectedDistrict(district);

    const districtData = availableDistricts.find(d => d.Name === district);
    setAvailableSuburbs(districtData ? districtData.Suburbs : []);
    handleChange("district", district);
  };

  // Handle suburb change
  const handleSuburbChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleChange("suburb", e.target.value);
  };

  return (
    <>
      <GridContainer
        repeat={smallForm ? 1 : 3}
        mobileRepeat={2}
        gap="15px"
        width="100%"
        style={{ marginBottom: "15px" }}
      >
        <Select
          label={showLabel ? "Region" : ""}
          theme="filled"
          id="region"
          placeholder="Please select"
          onChange={handleRegionChange}
          value={inputs.region || selectedRegion}
          options={regions.map(region => ({ title: region.Name, value: region.Name }))}
        />

        <Select
          label={showLabel ? "District" : ""}
          theme="filled"
          id="district"
          placeholder="Please select"
          onChange={handleDistrictChange}
          value={inputs.district || selectedDistrict}
          disabled={!availableDistricts.length}
          options={availableDistricts.map(region => ({ title: region.Name, value: region.Name }))}
        />

        <Select
          label={showLabel ? "Suburb" : ""}
          theme="filled"
          id="suburb"
          placeholder="Please select"
          onChange={handleSuburbChange}
          value={inputs.suburb}
          disabled={!availableSuburbs.length}
          options={availableSuburbs.map(region => ({ title: region.Name, value: region.Name }))}
        />
      </GridContainer>

      <GridContainer repeat={smallForm ? 1 : 2} mobileRepeat={2} gap="15px" width="100%">
        {/* Other Address Fields */}
        <TextField
          theme={theme}
          label={showLabel ? "Street #" : undefined}
          id="street"
          placeholder="Street #"
          // autoComplete="street-address"
          value={inputs.streetNumber}
          onChange={e => handleChange("streetNumber", e.target.value)}
        />
        <TextField
          theme={theme}
          label={showLabel ? "Unit, Apartment..." : ""}
          id="unit"
          placeholder="Unit, Apartment..."
          value={inputs.unit}
          onChange={e => handleChange("unit", e.target.value)}
        />
        <TextField
          theme={theme}
          label={showLabel ? "Street Name" : undefined}
          id="street"
          placeholder="Street Name"
          // autoComplete="street-address"
          value={inputs.street}
          onChange={e => handleChange("street", e.target.value)}
        />
      </GridContainer>
    </>
  );
};

export default AddressInput;
