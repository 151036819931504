import { Maybe, SanityAgent, SanityAttachedFile, SanityPropertySeller } from "@graphql-types";
import React, { Dispatch, SetStateAction } from "react";

import { Button } from "@global";
import { Container } from "@util/standard";
import { colors } from "@util/constants";
import styled from "styled-components";
import { LogoType } from "@util/types";
import { navigate } from "gatsby";
import { mediaQuery } from "@styles/mediaQueries";

interface SideColumnProps {
  files?: Maybe<Maybe<SanityAttachedFile>[]>;
  globalFiles?: Maybe<Maybe<SanityAttachedFile>[]> | undefined;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  agent?: Maybe<SanityAgent> | undefined;
  owner?: Maybe<SanityPropertySeller> | undefined;
  listingType: LogoType | undefined;
  status?: Maybe<string>;
}

const ButtonStyled = styled(Button)`
  background-image: url("data:image/svg+xml,%3Csvg width='29' height='23' viewBox='0 0 29 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3633 0.25C14.7775 0.25 15.1133 0.585786 15.1133 1V15C15.1133 15.4142 14.7775 15.75 14.3633 15.75C13.9491 15.75 13.6133 15.4142 13.6133 15V1C13.6133 0.585786 13.9491 0.25 14.3633 0.25Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.66592 10.9239C9.96147 10.6336 10.4363 10.638 10.7265 10.9335L14.3795 14.6535L17.858 11.111C18.1483 10.8155 18.6231 10.8112 18.9187 11.1014C19.2142 11.3916 19.2185 11.8664 18.9283 12.162L14.3795 16.7944L9.65627 11.9845C9.36606 11.6889 9.37038 11.2141 9.66592 10.9239Z' fill='%23133456'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.3884 22.75H0.335938V21.25H28.3884V22.75Z' fill='%23133456'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  text-align: left;
  transition: background 0.3s ease;

  span {
    padding: 20px 80px 20px 40px;

    ${mediaQuery.tabletDown} {
      padding: 16px 80px 16px 20px;
    }
  }

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='29' height='23' viewBox='0 0 29 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.3633 0.25C14.7775 0.25 15.1133 0.585786 15.1133 1V15C15.1133 15.4142 14.7775 15.75 14.3633 15.75C13.9491 15.75 13.6133 15.4142 13.6133 15V1C13.6133 0.585786 13.9491 0.25 14.3633 0.25Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.66592 10.9239C9.96147 10.6336 10.4363 10.638 10.7265 10.9335L14.3795 14.6535L17.858 11.111C18.1483 10.8155 18.6231 10.8112 18.9187 11.1014C19.2142 11.3916 19.2185 11.8664 18.9283 12.162L14.3795 16.7944L9.65627 11.9845C9.36606 11.6889 9.37038 11.2141 9.66592 10.9239Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M28.3884 22.75H0.335938V21.25H28.3884V22.75Z' fill='white'/%3E%3C/svg%3E%0A");
    background-color: ${colors.main};

    &:after {
      opacity: 0;
    }
  }
`;

const SideColumn = ({
  files,
  globalFiles,
  setFormOpen,
  agent,
  owner,
  listingType,
  status,
}: SideColumnProps) => {
  return (
    <>
      {/* {status === "listing" && ( */}
      <Container flexDirection="column" margin="50px 0 0 0" tabletMargin="30px 0 0 ">
        <h4>Get in touch</h4>
        <Button
          linkText="Expression of Interest"
          theme="base"
          onClick={() => setFormOpen(true)}
          margin="0 0 20px 0"
        />
        {listingType === "pro" && agent && (
          <>
            <h5>Listing Agent: {agent.name}</h5>
            <Button
              linkText={`Email: ${agent.email}`}
              theme="base"
              onClick={() => navigate(`mailto:${agent.email}`)}
              margin="0 0 10px 0"
            />
            <Button
              linkText={`Call: ${agent.phone}`}
              theme="base"
              onClick={() => navigate(`tel:${agent.phone}`)}
              margin="0 0 20px 0"
            />
          </>
        )}
        {owner && owner.mainEmail !== "" && (
          <>
            <h5>Owner: {owner.mainFirstName}</h5>
            {owner.mainEmail && (
              <Button
                linkText={`Email owner`}
                theme="base"
                onClick={() => navigate(`mailto:${owner.mainEmail}`)}
                margin="0 0 10px 0"
              />
            )}
            {owner.mainPhone && (
              <Button
                linkText={`Call: ${owner.mainPhone}`}
                theme="base"
                onClick={() => navigate(`tel:${owner.mainPhone}`)}
              />
            )}
          </>
        )}
      </Container>
      {/* )} */}

      {files && files.length > 0 && (
        <Container margin="55px 0 0" flexDirection="column" tabletMargin="10px 0 0">
          <h4>Documents</h4>
          {files.map(file => {
            if (!file) return;

            const linkAddress = file.fileLink ?? `${file.file?.asset?.url}?dl`;

            return (
              <ButtonStyled
                key={file._key}
                externalLink={linkAddress}
                linkText={file.fileName?.split(".")[0]}
                width="100%"
                theme="baseOutline"
                margin="10px 0px"
              />
            );
          })}
        </Container>
      )}
      {globalFiles && globalFiles.length > 0 && (
        <Container margin="55px 0 0" flexDirection="column" tabletMargin="30px 0 0 ">
          <h4>Property Documents</h4>
          {globalFiles.map(file => {
            if (!file) return;

            const linkAddress = file.fileLink ?? `${file.file?.asset?.url}?dl`;

            return (
              <ButtonStyled
                key={file._key}
                externalLink={linkAddress}
                linkText={file.fileName?.split(".")[0]}
                width="100%"
                theme="baseOutline"
                margin="10px 0px"
              />
            );
          })}
        </Container>
      )}
    </>
  );
};

export default SideColumn;
