import { Link, PropertyIcons } from "@global";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT, colors, colorsRGB } from "@util/constants";
import {
  em,
  formatPropertyAddress,
  getPackageCategory,
  splitCamelCase,
  toDollar,
} from "@util/helper";
import { LogoType } from "@util/types";
import React from "react";
import { SanityImageWithMeta, SanityProperty, SanityPropertySaleDetails } from "@graphql-types";
import styled from "styled-components";
import { useHandleQuery } from "@api";

interface Props {
  data?: SanityProperty | null;
  title?: string;
  isAccount?: boolean;
  propertyId?: string;
  overrideType?: LogoType | null;
}

const Placeholder = ({
  title = "Unavailable",
  propertyId,
}: {
  title?: string;
  propertyId?: string;
}) => {
  return (
    <Link internalSlug={"/account/property"} state={{ propertyId: propertyId }}>
      <Wrapper>
        <ImageWrapper isEmpty>
          <span>{title}</span>
        </ImageWrapper>
        <ContentWrapper isEmpty className="rte content-wrapper">
          <p>Address</p>
          <p>
            <PropertyIcons bed="0" bath="0" scale={0.8} />
          </p>
          <p>Price</p>
        </ContentWrapper>
      </Wrapper>
    </Link>
  );
};

const getPhotoUrl = (val?: string | SanityImageWithMeta | null) => {
  if (!val) return "";
  let url = typeof val === "string" ? val : val?.asset?.url;
  if (url && !url.startsWith("http")) url = `https://${url}`;
  return url ?? "";
};

export const getPriceLabel = (data: SanityPropertySaleDetails, isInner = false) => {
  const auctionDate = (date: string, time: string) => {
    const dateObj = new Date(date);
    const timeObj = new Date(`${dateObj.toDateString()} ${time}`);
    return `: ${dateObj.toDateString()} ${timeObj.toLocaleTimeString("en-NZ", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })}`;
  };

  if (data.type === "priceByNegotiation") return "Price By Negotiation";
  if (data.type === "askingPrice" && data.price) return `Asking Price ${toDollar(data.price)}`;
  if (data.type === "enquiriesOver" && data.price) return `Enquiries Over ${toDollar(data.price)}`;
  if (data.type === "auction") return `Auction ${isInner ? auctionDate(data.date, data.time) : ""}`;
  return splitCamelCase(data.type, "capitalize");
};

const PropertyCard = ({ data, title, isAccount, propertyId, overrideType }: Props) => {
  const packageCategory = overrideType ?? getPackageCategory(data);
  const handles = useHandleQuery();
  const isSold = data?.status === "unconditional" || data?.status === "sold";
  const photo = [...(data?.photos ?? []), ...(data?.photosUrls ?? [])][0] as
    | SanityImageWithMeta
    | string;

  if (!data) {
    return <Placeholder title={title} propertyId={propertyId} />;
  }
  return (
    <Wrapper>
      <Link
        internalSlug={
          isAccount
            ? "/account/property"
            : `/${handles.property?.slug?.current}/${data.slug?.current ?? data.slug ?? "#"}`
        }
        state={isAccount ? data : null}
      >
        {photo ? (
          <ImageWrapper>
            <img className="static" style={{ objectFit: "cover" }} src={getPhotoUrl(photo)} />
            {packageCategory && <Tag type={packageCategory}>{packageCategory}</Tag>}
            {isSold && <SaleTag type={packageCategory}>Sold</SaleTag>}
          </ImageWrapper>
        ) : (
          <ImageWrapper isEmpty>
            {packageCategory && <Tag type={packageCategory}>{packageCategory}</Tag>}
            <span>{title}</span>
          </ImageWrapper>
        )}
        <ContentWrapper className="rte content-wrapper">
          <p>{formatPropertyAddress(data?.address)}</p>
          <p>
            <PropertyIcons bed={data.details?.bed} bath={data.details?.bath} scale={0.8} />
          </p>
          {data.saleDetails?.type ? <p>{getPriceLabel(data.saleDetails)}</p> : null}
        </ContentWrapper>
      </Link>
    </Wrapper>
  );
};

export default PropertyCard;

const Wrapper = styled.div`
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
  background-color: ${colors.lightGrey};

  a {
    width: 100%;
    height: 100%;
    &:hover {
      .gatsby-image-wrapper,
      .static {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    h5 {
      font-size: ${em(18, 16)};
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 300px;
  }
`;

const ImageWrapper = styled.div<{
  isEmpty?: boolean;
}>`
  width: 100%;
  padding-bottom: 67%;
  position: relative;
  background: ${({ isEmpty }) => (isEmpty ? colorsRGB.main(0.2) : colors.overlay)};
  overflow: hidden;
  .static {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${({ isEmpty }) =>
    isEmpty &&
    `
    display: flex;
    align-items: center;
    justify-content: center;

    span{
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`;

const Tag = styled.div<{ type: LogoType }>`
  position: absolute;
  z-index: 2;
  left: 0;
  top: 30px;
  background: ${({ type }) => colors[type]};
  color: ${colors.white};
  border-radius: 0 100px 100px 0;
  padding: 10px 20px 10px 15px;
  text-transform: capitalize;
`;

const SaleTag = styled.div<{ type: LogoType }>`
  position: absolute;
  z-index: 1;
  top: 10%;
  right: -40%;
  width: 100%;
  text-align: center;
  transform: rotate(45deg);

  background: ${props => (props.type ? colors[props.type] : colors.main)};
  color: ${colors.white};
  font-size: ${em(15, 16)};
  padding: 10px 27px 8px;
  border-radius: 0 30px 0 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 10px 25px;
  }
`;

const ContentWrapper = styled.div<{
  isEmpty?: boolean;
}>`
  padding: 50px;
  ${({ isEmpty }) =>
    isEmpty &&
    `
    opacity: 0.5;
  `}

  p {
    border-bottom: 1px solid ${colorsRGB.main(0.5)};
    padding-bottom: 20px;

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 20px;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 40px 30px 50px;
  }
`;
