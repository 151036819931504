import React, { useRef, useState } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";
import {
  InstantSearch,
  connectHits,
  Index,
  Configure,
  SearchBox,
  RefinementList,
  RangeInput,
  Pagination,
  //@ts-ignore
} from "react-instantsearch-dom";

import { Query, SanityProperty } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import FooterSections from "@shared/footer/footerSections";
import { Container, PageWidth, PropertyWrapper, Section } from "@util/standard";
import { PropertyCard, SearchMenu } from "@global";
import { Headerwrapper } from "./blogInnerTemplate";
import { useStore } from "@state/store";
import { searchClient } from "@util/helper";
import { colors, fontWeights, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "@util/constants";
import { useOnClickOutside } from "@util/hooks";
import { SearchParams } from "@state/types";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const InstantSearchWrapper = styled.div`
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  opacity: 0;
`;

const HeaderwrapperStyled = styled(Headerwrapper)`
  border-bottom: 0;
  padding-bottom: 0;
  align-items: center;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 30px;

    align-items: flex-start;
    .ais-SortBy,
    select {
      width: 100%;
    }
  }
`;

const PaginationStyled = styled(Pagination)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 80px;

  ul {
    display: flex;
    flex-flow: row wrap;
  }
  li {
    display: inline-block;
    padding: 10px;
    &.ais-Pagination-item--selected {
      font-weight: ${fontWeights.bold};
      text-decoration: underline;
      text-underline-offset: 7px;
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-top: 50px;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-flow: column nowrap;
    width: 100%;
  }
`;

const Filter = styled.div`
  position: relative;
  line-height: 1.2;
  cursor: pointer;

  div {
    padding: 15px 55px 15px 20px;
    display: flex;
    align-items: center;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.5;
    }
  }

  @media only screen and (min-width: ${MOBILE_BREAKPOINT + 1}px) {
    ul {
      left: auto;
      right: 0;
      top: 100%;
    }
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    margin-bottom: 15px;
    background: ${colors.lightBlue};
    border-radius: 5px;
  }
`;

const Select = styled.div`
  position: relative;
  line-height: 1.2;
  cursor: pointer;
  width: 100%;

  div {
    border-radius: 5px;
    border: 2px solid ${colors.main};
    padding: 15px 55px 15px 20px;
    width: 100%;
    display: inline-block;

    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 7L11 1' stroke='currentColor'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 23px);
  }
`;

const Ul = styled.ul<{ isVisible?: boolean }>`
  position: absolute;
  top: calc(100% - 3px);
  left: 0;
  z-index: 3;
  width: 100%;
  min-width: 200px;
  background: ${colors.white};
  border: 2px solid ${colors.main};

  ${({ isVisible }) =>
    isVisible
      ? `
    opacity: 1;
  `
      : `
    pointer-events: none;
    opacity: 0;
    `}
`;

const Li = styled.li<{ isSelected?: boolean; isFilter?: boolean }>`
  padding: 10px 20px;
  transition: background 0.3s ease, opacity 0.3s ease;

  &:hover {
    background: ${colors.lightBlue};
  }
  ${({ isSelected, isFilter }) => {
    if (!isSelected) return ``;
    if (isSelected && !isFilter)
      return `
      display: none;
    `;
    return `
      background: ${colors.main};
      color: ${colors.white};
      opacity: 1;
      &:hover{
        background: ${colors.main};
        opacity: 0.6;
      }
    `;
  }}
`;

const PropertyHits = ({ hits }: any) => {
  const { searchParams } = useStore();

  if (hits.length === 0 && searchParams.searched) {
    return <div>No results found.</div>;
  }

  return (
    <PropertyWrapper>
      {searchParams.searched && (
        <>
          {hits.map((hit: SanityProperty) => {
            return <PropertyCard data={hit} key={hit._id} />;
          })}
        </>
      )}
    </PropertyWrapper>
  );
};

const CustomPropertyHits = connectHits(PropertyHits);

const setSearchTrue = (searchParams: SearchParams) => {
  useStore.setState({
    searchParams: {
      ...searchParams,
      searched: true,
    },
  });
};

export default function BrowsePropertiesTemplate({ data, pageContext }: Props) {
  const sortBy = [
    {
      value: "properties",
      label: "Sort by",
    },
    {
      value: "properties_created_desc",
      label: "Newest",
    },
    {
      value: "properties_created_asc",
      label: "Oldest",
    },
    {
      value: "properties_price_desc",
      label: "Highest Price",
    },
    {
      value: "properties_price_asc",
      label: "Lowest Price",
    },
  ];

  const filterBy = [
    {
      value: "",
      label: "None",
    },
    {
      value: "Auction",
      label: "Auction",
    },
    {
      value: "unconditional",
      label: "Sold",
    },
  ];
  const { searchParams } = useStore();
  const [sortValue, setSortValue] = useState(sortBy[0]);
  const [filterValue, setFilterValue] = useState(filterBy[0]);
  const [sortOpen, setSortOpen] = useState(false);
  //const [filterOpen, setFilterOpen] = useState(false);
  const selectEl = useRef(null);
  //const filterEl = useRef(null);

  if (!data) return null;

  const seo = data.sanityRegion?.seo ?? data.sanityPropertyTemplate?.seo;
  const regionName = data.sanityRegion?.regionName;

  useOnClickOutside(selectEl, () => setSortOpen(false));
  //useOnClickOutside(filterEl, () => setFilterOpen(false));

  const handleClear = () => {
    setSortValue(sortBy[0]);
    setFilterValue(filterBy[0]);
  };

  return (
    <>
      <SEO seoData={seo} slug={pageContext.pagePath} />
      <InstantSearch indexName="properties" searchClient={searchClient}>
        {/* InstantSearch */}
        <InstantSearchWrapper>
          <Configure hitsPerPage={20} />
          {searchParams.searchTerm && <SearchBox defaultRefinement={searchParams.searchTerm} />}
          <RefinementList
            attribute="address.region"
            defaultRefinement={searchParams.region?.name ? [searchParams.region?.name] : []}
            operator="or"
          />
          <RefinementList
            attribute="address.district"
            defaultRefinement={searchParams.district?.name ? [searchParams.district.name] : []}
            operator="or"
          />
          <RefinementList
            attribute="details.bath"
            defaultRefinement={searchParams.bathrooms}
            operator="or"
          />
          <RefinementList
            attribute="details.bed"
            defaultRefinement={searchParams.bedrooms}
            operator="or"
          />
          {/* <RefinementList
            attribute="propertyType"
            defaultRefinement={searchParams.types}
            operator="or"
          /> */}
          {/* {filterValue.value === "Auction" && (
            <RefinementList
              attribute="saleDetails.type"
              defaultRefinement={[filterValue.value]}
              operator="or"
            />
          )}
          {filterValue.value === "unconditional" && (
            <RefinementList
              attribute="status"
              defaultRefinement={[filterValue.value, "sold"]}
              operator="or"
            />
          )} */}
          <RangeInput
            attribute="saleDetails.price"
            defaultRefinement={{
              min: searchParams.minPrice?.value,
              max: searchParams.maxPrice?.value,
            }}
          />
        </InstantSearchWrapper>
        {/* InstantSearchEND */}

        <Section isTop>
          <PageWidth>
            <Container margin="0 0 100px" width="100%" tabletMargin="0 0 30px">
              <SearchMenu onClear={handleClear} />
            </Container>
            <HeaderwrapperStyled>
              <h1 className="h3">
                {regionName ? `Browse ${regionName} Properties` : `Browse Properties`}
              </h1>
              <FilterWrapper>
                {/* <Container>
                  <Filter ref={filterEl} onClick={e => setFilterOpen(!filterOpen)}>
                    <div>
                      <FilterIcon color="current" margin="0 20px 0 0" />
                      Filter
                    </div>
                    <Ul isVisible={filterOpen}>
                      {filterBy.map(filter => (
                        <Li
                          key={filter.value}
                          data-value={filter.value}
                          isFilter
                          isSelected={filter.value === filterValue.value}
                          onClick={e => {
                            setSearchTrue(searchParams);
                            setFilterValue(filter);
                          }}
                        >
                          {filter.label}
                        </Li>
                      ))}
                    </Ul>
                  </Filter>
                </Container> */}
                <Container width="220px" mobileWidth="100%">
                  <Select ref={selectEl} onClick={e => setSortOpen(!sortOpen)}>
                    <div>{sortValue.label}</div>
                    <Ul isVisible={sortOpen}>
                      {sortBy.map(sort => (
                        <Li
                          key={sort.value}
                          data-value={sort.value}
                          isSelected={sort.value === sortValue.value}
                          onClick={e => {
                            setSearchTrue(searchParams);
                            setSortValue(sort);
                          }}
                        >
                          {sort.label}
                        </Li>
                      ))}
                    </Ul>
                  </Select>
                </Container>
              </FilterWrapper>
            </HeaderwrapperStyled>
            {searchParams.searched ? (
              <Index indexName={sortValue.value}>
                <CustomPropertyHits />
              </Index>
            ) : (
              <PropertyWrapper>
                {data.allSanityProperty?.nodes &&
                  data.allSanityProperty.nodes.map(property => {
                    if (!property) return;
                    if (property.status === "prospect") return null;
                    return <PropertyCard key={property._id} data={property} />;
                  })}
              </PropertyWrapper>
            )}
            {searchParams.searched && <PaginationStyled showFirst={false} />}
          </PageWidth>
        </Section>
      </InstantSearch>
      <FooterSections />
    </>
  );
}

export const query = graphql`
  query BrowsePropertiesQuery($slug: String) {
    sanityPropertyTemplate {
      seo {
        ...sanitySeo
      }
    }
    sanityRegion(slug: { current: { eq: $slug } }) {
      regionName
      seo {
        ...sanitySeo
      }
    }
    allSanityProperty(
      filter: { status: { nin: ["prospect", "withdrawn"] } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        ...sanityFeaturedProperty
      }
    }
  }
`;
